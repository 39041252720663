

























































import { Component, Vue } from 'vue-property-decorator'
import { PATH } from '@/core/apis/api.nobile'
import { BSpinner } from 'bootstrap-vue'
import axios from 'axios'

interface Place {
  'loc_id': number;
  'loc_local': string;
  'loc_lat': string;
  'loc_long': string;
  'loc_especificacao': string;
  'loc_prefeitura': string;
  'paineis': Array<{'pai_id': number, 'foto': string}>;
}

@Component({
  components: {
    BSpinner
  }
})
export default class Point extends Vue {
  // eslint-disable-next-line no-undef
  map: google.maps.Map = {} as google.maps.Map;
  markers: unknown[] = []
  notFound = false
  selectedPicture?: Place
  hasSelectedPicture = false
  slide = 1
  loading = true
  item?: Place = {
    loc_id: 0,
    loc_local: '',
    loc_lat: '',
    loc_long: '',
    loc_especificacao: '',
    loc_prefeitura: '',
    paineis: [
      {
        pai_id: 0,
        foto: ''
      }
    ]
  }

  constructor () {
    super()
    document.title = 'Nobile - Informações do ponto'
  }

  created (): void {
    this.getPoints()
  }

  async getPoints (): Promise<void> {
    const pointId = this.$route.params.id
    const url = `${PATH}/api/pontos/${pointId}`

    try {
      const res = await axios.get(url)

      if (res.data) {
        this.item = res.data
        this.loadMap(res.data as Place)
        this.loading = false
      }
    } catch (err) {
      this.notFound = true
      this.loading = false
    }
  }

  loadMap (point: Place): void {
    const center = { lat: +point.loc_lat, lng: +point.loc_long }

    // eslint-disable-next-line no-undef
    this.map = new google.maps.Map(
      document.getElementById('map') as HTMLElement,
      {
        center,
        zoom: 16
      }
    )

    if (this.map) {
      this.markers = [{
        // eslint-disable-next-line no-undef
        marker: new google.maps.Marker({
          position: center,
          map: this.map
        })
      }]
    }
  }

  onSelectPicture (res: number): void {
    this.slide = res
    this.hasSelectedPicture = true

    setTimeout(() => {
      this.$bvModal.show('single')
    }, 100)
  }

  resetPicture () : void {
    this.selectedPicture = undefined
    this.hasSelectedPicture = false
  }
}
