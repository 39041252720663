



































































































































































import { Component, Vue } from 'vue-property-decorator'
import { BSpinner } from 'bootstrap-vue'
import MyButton from '@/components/MyButton.vue'
import CheckinService, { Checkin, Reservation as ReservationItem } from '@/core/service/checkin'
import DEVICE from '@/core/service/device'
import JSZip from 'jszip'

/* eslint-disable */
const JSZipUtils = require('jszip-utils')
const FileSaver = require('file-saver')
/* eslint-enable */

@Component({
  components: {
    MyButton,
    BSpinner
  }
})
export default class Reservation extends Vue {
  // eslint-disable-next-line no-undef
  map: google.maps.Map = {} as google.maps.Map;
  semana = ''
  periodo = ''
  slide = 0;

  reservations: ReservationItem[] = []
  selected?: ReservationItem
  hasSelected = false
  markers: unknown[] = []
  loading = false

  constructor () {
    super()
    document.title = 'Minhas reservas - Nobile Área do Cliente'
  }

  created (): void {
    const id = this.$route.params.id
    const week = this.$route.query.week

    if (!id || !week) {
      this.$router.replace('/')
      return
    }

    this.semana = week as string
    this.getReservations(+id)
  }

  mounted (): void {
    this.selected = undefined
    this.hasSelected = false
  }

  get isMobile ():boolean {
    return DEVICE.isMobile.state
  }

  async getReservations (id: number): Promise<void> {
    this.loading = true
    const res = await CheckinService.getWeek(id)
    this.reservations = [...res]
    this.loading = false

    if (res.length) {
      this.periodo = res[0].periodo
      this.initMap([...res])
    }
    // TODO: remove
  }

  showAll (): void {
    this.selected = this.reservations[0]
    this.hasSelected = true

    setTimeout(() => {
      this.$bvModal.show('gallery')
    }, 100)
  }

  onSelect (res: ReservationItem): void {
    this.selected = res
    this.hasSelected = true

    setTimeout(() => {
      this.$bvModal.show('single')
    }, 100)
  }

  download (items: Array<ReservationItem>): void {
    const urls: Array<string> = []
    this.loading = true

    items.forEach((item: ReservationItem) => {
      item.checkins.forEach((check: Checkin) => {
        const url: string = check.ckn_img.replace('http://', 'https://')
        urls.push(url)
      })
    })

    this.zipFiles(urls)
  }

  zipFiles (items: Array<string>): void {
    const zipfile = new JSZip()
    const zipFilename = 'nobile_checkins_fotos.zip'
    let count = 0

    items.forEach((url: string) => {
      // pegando apenas o nome do arquivo, sem url e sem queryString
      const urlArr = url.split('/')
      const filename = urlArr[urlArr.length - 1].split('?')[0]

      JSZipUtils.getBinaryContent(url, (err: string, res: string) => {
        if (err) {
          return false
        }

        zipfile.file(filename, res, { binary: true })

        count++

        if (count === items.length) {
          zipfile.generateAsync({ type: 'blob' }).then(content => {
            FileSaver.saveAs(content, zipFilename)
            this.loading = false
          })
        }
      })
    })
  }

  reset (): void {
    this.hasSelected = false
    this.selected = undefined
  }

  initMap (points: ReservationItem[]): void {
    // console.log(points, points.length)
    const center = { lat: +points[0].lat, lng: +points[0].long }

    // eslint-disable-next-line no-undef
    this.map = new google.maps.Map(
      document.getElementById('map') as HTMLElement,
      {
        center,
        zoom: 12
      }
    )

    // The marker, positioned at Uluru

    if (this.map) {
      points.forEach(p => {
        const pos = { lat: +p.lat, lng: +p.long }
        // eslint-disable-next-line no-undef
        const marker = new google.maps.Marker({
          position: pos,
          map: this.map
        })

        this.markers.push(marker)
      })
      // eslint-disable-next-line no-undef
      // const marker = new google.maps.Marker({
    }
  }
}
