import ApiNobile, { LoginResponse, LogoutResponse, ResetPassResponse, Response } from '../apis/api.nobile'

export default class AuthService {
  static async login (
    user: string,
    pass: string
  ): Promise<Response<LoginResponse>> {
    const res = await ApiNobile.AUTH.login(user, pass)
    if (res.ok) {
      localStorage.setItem('nobile-token', res.data?.accessToken as string)
    }

    return res
  }

  static isAuthenticated (): boolean {
    const token = localStorage.getItem('nobile-token')
    return !!token
  }

  static logout (): void {
    return localStorage.removeItem('nobile-token')
  }

  static async updatePass (pass: string, old: string): Promise<Response<ResetPassResponse>> {
    const res = await ApiNobile.AUTH.update(old, pass)
    if (res.ok) {
      localStorage.setItem('nobile-token', res.data?.token as string)
    }

    return res
  }

  static async resetPass (pass: string, token: string): Promise<Response<ResetPassResponse>> {
    const res = await ApiNobile.AUTH.reset(pass, token)
    if (!res.ok) return res
    return res
  }

  static async requestReset (email: string): Promise<Response<any>> {
    const res = await ApiNobile.AUTH.requestReset(email)
    if (!res.ok) return res
    return res
  }
}
