


























import { Component, Vue } from 'vue-property-decorator'
import MyButton from '@/components/MyButton.vue' // @ is an alias to /src
import AuthService from '@/core/service/auth'

@Component({
  components: {
    MyButton
  }
})
export default class RecoverConfirm extends Vue {
  hasToken = false

  pass = ''
  passCheck = ''

  error = ''
  success = false

  private token = ''

  constructor () {
    super()
    document.title = 'Nobile - Confirmar recuperação de senha'
  }

  created (): void {
    const token = this.$route.query.token
    this.hasToken = !!token
    this.token = token as string
  }

  check (): boolean {
    if (!this.pass) {
      this.error = 'Informe uma senha para continuar'
      return false
    }

    if (this.pass.length < 6) {
      this.error = 'Sua senha deve conter pelo menos 6 caracteres'
      return false
    }

    if (this.pass && !this.passCheck) {
      this.error = 'Confirme sua senha para continuar'
      return false
    }

    if (this.pass !== this.passCheck) {
      this.error = 'enhas não conferem'
      return false
    }

    return true
  }

  async confirm (): Promise<void> {
    const check = this.check()

    if (!check) return

    this.success = false
    const res = await AuthService.resetPass(this.pass, this.token)

    if (!res.ok) {
      this.error = res.error as string
      return
    }

    this.success = true
    this.token = ''
  }

  goTo (): void {
    this.$router.replace('/login')
  }
}
