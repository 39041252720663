









































































import { Component, Vue } from 'vue-property-decorator'

import MyButton from '@/components/MyButton.vue'
import AuthService from '@/core/service/auth'
import DEVICE from '@/core/service/device'

interface Error {
  type: 'user' | 'pass' | 'server' | undefined,
  msg: string | undefined
}

@Component({
  components: {
    MyButton
  }
})
export default class Login extends Vue {
  user = ''
  pass = ''

  erro: Error

  constructor () {
    super()
    document.title = 'Login - Nobile Área do Cliente'
    this.erro = { type: undefined, msg: undefined }
  }

  get btnVariant (): string {
    console.log(DEVICE.isMobile.state)
    return DEVICE.isMobile.state ? 'clean-danger' : 'clean-info'
  }

  cantLogin (): Error | undefined {
    if (!this.user) {
      return { type: 'user', msg: 'Digite seu nome de usuário.' }
    }

    if (!this.pass) {
      return { type: 'pass', msg: 'Digite uma senha.' }
    }
  }

  resetError (): void {
    this.erro.type = undefined
    this.erro.msg = undefined
  }

  async login (): Promise<void> {
    this.resetError()

    const cant = this.cantLogin()
    if (cant) {
      this.erro = { ...cant }
      return
    }

    const res = await AuthService.login(this.user, this.pass)
    console.log(res)
    if (!res.ok) {
      this.erro = { type: 'server', msg: res?.error as string }
      return
    }

    this.$router.replace('/')
  }

  goToRecover (): void {
    this.$router.push('recuperar-senha')
  }
}
