import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import Dash from '../layout/Dash.vue'

import Login from '../views/Login.vue'
import Points from '../views/Points.vue'
import Point from '../views/Point.vue'
// import RecoverPass from '../views/RecoverPass.vue'
import RecoverConfirm from '../views/RecoverConfirm.vue'

import Home from '../views/Home.vue'
// import Profile from '../views/Profile.vue'
// import ChangePass from '../views/ChangePass.vue'
import Reservation from '../views/Reservation.vue'
import AuthService from '@/core/service/auth'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Dash,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      // {
      //   path: 'meus-dados',
      //   name: 'Meus dados',
      //   component: Profile
      // },
      {
        path: 'minhas-reservas/:id',
        name: 'Minhas Reservas',
        component: Reservation
      }
      // {
      //   path: 'alterar-senha',
      //   name: 'Alterar Senha',
      //   component: ChangePass
      // }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/pontos',
    name: 'Pontos',
    component: Points
  },
  {
    path: '/pontos/:id',
    name: 'Ponto',
    component: Point
  },
  // {
  //   path: '/recuperar-senha',
  //   name: 'Recuperar senha',
  //   component: RecoverPass
  // },
  {
    path: '/password_reset',
    name: 'Confirme sua nova senha',
    component: RecoverConfirm
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const publicRouters = ['Login', 'Recuperar senha', 'Confirme sua nova senha', 'Pontos', 'Ponto']

function isPublic (name: string) {
  return publicRouters.includes(name)
}

router.beforeEach((to, from, next) => {
  if (!isPublic(to.name as string) && !AuthService.isAuthenticated()) next({ name: 'Login' })
  else next()
})

export default router
