






import { Component, Prop, Vue } from 'vue-property-decorator'
import { BButton } from 'bootstrap-vue'

@Component({
  components: {
    BButton
  }
})
export default class MyButton extends Vue {
  @Prop({ default: '' }) private label!: string
  @Prop({ default: 'default' }) private variant!:
    | 'default'
    | 'clean'
    | 'danger';

  @Prop({ default: '' }) private customClass?: string
  @Prop({ default: '' }) private size?: 'full' | 'fit'

  // @Prop() private click? = () => {
  //   return
  // }

  get classes (): string {
    return `btn ${this.variant} ${this.customClass} ${this.size}`
  }
}
