import Vue from 'vue'

class DeviceService {
  isMobile = Vue.observable({ state: window.innerWidth <= 420 })

  onResize (width: number): void {
    this.isMobile.state = width <= 1023
  }
}

const DEVICE = new DeviceService()

export default DEVICE
