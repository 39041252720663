




import { Component, Vue } from 'vue-property-decorator'

import DEVICE from '@/core/service/device'

@Component
export default class App extends Vue {
  constructor () {
    super()
    this.handleResize()
  }

  handleResize (): void {
    window.addEventListener('resize', (ev: UIEvent) => {
      DEVICE.onResize((ev.target as Window).innerWidth)
    })
  }
}

