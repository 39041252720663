













































import { Component, Vue } from 'vue-property-decorator'
import { BSpinner } from 'bootstrap-vue'
import MyButton from '@/components/MyButton.vue' // @ is an alias to /src

import CheckinService, { YearOption, Period } from '@/core/service/checkin'

@Component({
  components: {
    MyButton,
    BSpinner
  }
})
export default class Home extends Vue {
  private year: number | null = null

  years: YearOption[] = []
  periods: Period[] = []

  visible = false
  loading = false

  constructor () {
    super()
    document.title = 'Check-in Fotográfio - Nobile Área do Cliente'
  }

  created (): void {
    this.getYears()
  }

  get options (): YearOption[] {
    return this.years
  }

  async getYears (): Promise<void> {
    this.loading = true
    this.years = await CheckinService.getYears()
    this.loading = false

    if (this.years.length) {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)

      if (urlParams.has('ano')) {
        this.setYear(urlParams.get('ano'))
      } else {
        this.setYear(this.years[0].value)
      }
    }
  }

  async getPeriodsByYear (year: number): Promise<void> {
    this.loading = true
    this.periods = await CheckinService.getPeriodsByYear(year)
    this.loading = false
  }

  setYear (year: any): void {
    if (!year) return

    window.history.pushState(year, '', `?ano=${year}`)
    this.year = year
    this.visible = true
    this.getPeriodsByYear(year)
  }

  async goTo (item: Period): Promise<void> {
    this.$router.push(`/minhas-reservas/${item.bis_id}?week=${item.bis_semana}`)
  }
}
