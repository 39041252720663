import ApiNobile, { Checkin, Period, Reservation, YearsResponse } from '../apis/api.nobile'

export interface YearOption { value: number | null, text: string }

export default class CheckinService {
  static async getYears (): Promise<YearOption[]> {
    // const place = { value: null, text: 'Selecione o ano que deseja visualizar' }
    const res = await ApiNobile.GET.years()
    if (!res.ok) {
      console.warn('[GET YEARS]', res.error)
      return []
    }

    const map: YearOption[] = (res.data as YearsResponse[]).map((res) => ({ value: res.ano, text: `${res.ano}` } as YearOption))
    map.unshift()
    return map
  }

  static async getPeriodsByYear (year: number): Promise<Period[]> {
    const res = await ApiNobile.GET.periods(year)
    if (!res.ok) {
      console.warn('[GET PERIODS]', res.error)
      return []
    }

    return res.data as Period[]
  }

  static async getWeek (week: number): Promise<Reservation[]> {
    const res = await ApiNobile.GET.week(week)
    if (!res.ok) {
      console.warn('[GET RESERVARTIONS]', res.error)
      return []
    }

    return res.data as Reservation[]
  }
}

export { Checkin, Period, Reservation }
