






import { Component, Vue } from 'vue-property-decorator'
import { PATH } from '@/core/apis/api.nobile'
import axios from 'axios'

interface Point {
  'loc_id': number;
  'loc_local': string;
  'loc_lat': string;
  'loc_long': string;
  'loc_especificacao': string;
  'loc_prefeitura': string;
  'paineis': Array<{'pai_id': number, 'foto': string}>;
}

@Component
export default class Points extends Vue {
  // eslint-disable-next-line no-undef
  map: google.maps.Map = {} as google.maps.Map;
  markers: unknown[] = []

  constructor () {
    super()
    document.title = 'Pontos - Nobile Área do Cliente'
  }

  created (): void {
    this.getPoints()
  }

  async getPoints (): Promise<void> {
    const url = `${PATH}/api/pontos`

    const res = await axios.get(url)

    if (res.data) {
      // console.log(res.data)
      this.loadMap(res.data as Point[])
    }
  }

  loadMap (points: Point[]): void {
    const center = { lat: +points[0].loc_lat, lng: +points[0].loc_long }

    // eslint-disable-next-line no-undef
    this.map = new google.maps.Map(
      document.getElementById('map') as HTMLElement,
      {
        center,
        zoom: 9
      }
    )

    if (this.map) {
      // eslint-disable-next-line no-undef
      let lastInfoWindow: google.maps.InfoWindow

      points.forEach(p => {
        let infoWindowContent = `
          <h3 class="h6">Local: ${p.loc_local}</h3>
          ${p.loc_especificacao}<br><br>
          ${(p.loc_prefeitura) ? '<b>Prefeitura:</b> ' + p.loc_prefeitura + '<br>' : ''}
          ${(p.loc_lat) ? '<b>Latitude:</b> ' + p.loc_lat + '<br>' : ''}
          ${(p.loc_long) ? '<b>Longitude:</b> ' + p.loc_long : ''}
          <br>

          <a href="https://nobileooh.com.br/local?id=${p.loc_id}" class="open-point" target="_parent">Acessar ></a>
        `

        if (p.paineis.length) {
          infoWindowContent += '<div class="infoWindowFotos">'

          p.paineis.forEach(f => {
            if (f.foto) {
              infoWindowContent += `<a href="${f.foto}" target="_blank"><img src="${f.foto}"></a>`
            }
          })

          infoWindowContent += '</div>'
        }

        const item = {
          // eslint-disable-next-line no-undef
          marker: new google.maps.Marker({
            position: { lat: +p.loc_lat, lng: +p.loc_long },
            map: this.map
          }),
          // eslint-disable-next-line no-undef
          infoWindow: new google.maps.InfoWindow({
            content: infoWindowContent
          })
        }

        // eslint-disable-next-line no-undef
        google.maps.event.addListener(item.marker, 'click', ((marker, content, infoWindow) => {
          return () => {
            if (lastInfoWindow) {
              lastInfoWindow.close()
            }

            infoWindow.setContent(content)
            infoWindow.setOptions({ maxWidth: 250 })
            infoWindow.open(this.map, marker)

            lastInfoWindow = infoWindow
          }
        // eslint-disable-next-line no-undef
        })(item.marker, infoWindowContent, item.infoWindow))

        this.markers.push(item)
      })
    }
  }
}
