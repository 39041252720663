























import { Component, Vue } from 'vue-property-decorator'
import MyButton from '@/components/MyButton.vue'
import AuthService from '@/core/service/auth'
import { PATH as ApiUrl } from '@/core/apis/api.nobile'

@Component({
  components: {
    MyButton
  }
})
export default class Menu extends Vue {
  private mapMenu: { [key: string]: string } = {
    home: '/',
    change: '/alterar-senha',
    data: '/meus-dados'
  };

  current = 'home'

  isActived (name: string): string {
    return this.$route.fullPath === this.mapMenu[name] ? 'actived' : ''
  }

  async logout (): Promise<void> {
    await AuthService.logout()
    this.$router.replace('/login')
  }

  goTo (name: string): void {
    this.$emit('change', name)

    this.current = name
    const path = this.mapMenu[name]

    this.$router.replace(path)
  }
}
