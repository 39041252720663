import axios, { AxiosResponse } from 'axios'
import router from '@/router'

import AuthService from '@/core/service/auth'
export interface Response<T> {
  ok: boolean;
  data?: T;
  error?: string;
}

export const PATH = 'https://sistema.nobileooh.com.br'

export function getHeader (): { Authorization: string } | undefined {
  const token = localStorage.getItem('nobile-token')
  return token ? { Authorization: `Bearer ${token}` } : undefined
}

export interface LoginResponse {
  accessToken?: string; //
  default?: string;
}

export interface LogoutResponse {
  status?: boolean;
}

export interface YearsResponse {
  ano: number
}

export interface ResetPassResponse {
  token: string
}

function processRes<T> (req: AxiosResponse, errorMsg?: string): Response<T> {
  if (req.status === 401) {
    AuthService.logout()
    router.replace('/login')
  }
  const ok = req.status.toString()[0] === '2'
  const error = !ok && errorMsg ? errorMsg : undefined
  return { ok, data: req.data as T, error }
}

export interface Period {
  'bis_id': number;
  'bis_anoi': string;
  'bis_semana': number;
  periodo: string;
}

export interface Checkin {
  'ckn_id': number;
  'ckn_img': string;
  'ckn_tipo': string;
  'ckn_data': string;
  'ckn_res': number;
}
export interface Reservation {
  'res_id': number;
  periodo: string;
  bairro: string;
  local: string;
  lat: string;
  long: string;
  painel: string;
  especificacao: string;
  status: string;
  checkins: Checkin[];
}

class Api {
  async login (
    username: string,
    password: string
  ): Promise<Response<LoginResponse>> {
    const url = `${PATH}/api/auth/login/`
    const res = await axios.post(url, { username, password })
      .catch(err => {
        if (err.message && (err.message === 'Network Error' || err.message === 'Request failed with status code 500')) {
          console.log('Não foi possível acessar o servidor neste momento, por favor tente mais tarde')
          return { status: 404 }
        }
        return err.response
      })

    return processRes<LoginResponse>(res, 'Nome de usuário ou senha inválida')
  }

  async logout () {
    const headers = getHeader()
    const url = `${PATH}/api/auth/logout/`
    const res = await axios.get(url, { headers })

    return processRes<LogoutResponse>(res, 'Não foi possível sair.')
  }

  async getYears () {
    const headers = getHeader()
    const url = `${PATH}/api/anos/`
    const res = await axios.get(url, { headers })
      .catch(err => {
        if (err.message && (err.message === 'Network Error' || err.message === 'Request failed with status code 500')) {
          console.log('Não foi possível acessar o servidor neste momento, por favor tente mais tarde')
          return { status: 404 }
        }
        return err.response
      })

    return processRes<YearsResponse[]>(res, 'Dados não encontrados')
  }

  async getPeriodsByYear (year: number): Promise<Response<Period[]>> {
    const headers = getHeader()
    const url = `${PATH}/api/periodos/${year}/`
    const res = await axios.get(url, { headers })
      .catch(err => {
        if (err.message && (err.message === 'Network Error' || err.message === 'Request failed with status code 500')) {
          console.log('Não foi possível acessar o servidor neste momento, por favor tente mais tarde')
          return { status: 404 }
        }
        return err.response
      })

    return processRes<Period[]>(res, 'Dados não encontrados')
  }

  async getWeek (bisweek: number): Promise<Response<Reservation[]>> {
    const url = `${PATH}/api/reservas/${bisweek}/`
    const headers = getHeader()
    const res = await axios.get(url, { headers })
      .catch(err => {
        if (err.message && (err.message === 'Network Error' || err.message === 'Request failed with status code 500')) {
          console.log('Não foi possível acessar o servidor neste momento, por favor tente mais tarde')
          return { status: 404 }
        }
        return err.response
      })

    return processRes<Reservation[]>(res, 'Dados não encontrados')
  }

  async recoverPass (email: string): Promise<Response<unknown>> {
    const url = `${PATH}/api/password_reset/`
    const body = { email }

    const res = await axios.post(url, body)
      .catch(err => {
        if (err.message && (err.message === 'Network Error' || err.message === 'Request failed with status code 500')) {
          console.log('Não foi possível acessar o servidor neste momento, por favor tente mais tarde')
          return { status: 404 }
        }
        return err.response
      })

    return processRes<unknown>(res, 'Algo inesperado aconteceu, por favor tente novamente')
  }

  async updatePass (oldP: string, newP: string): Promise<Response<ResetPassResponse>> {
    const url = `${PATH}/api/atualiza_senha/`
    const headers = getHeader()

    const body = {
      old_password: oldP,
      new_password: newP
    }

    const res = await axios.post(url, body, { headers })
      .catch(err => {
        if (err.message && (err.message === 'Network Error' || err.message === 'Request failed with status code 500')) {
          console.log('Não foi possível acessar o servidor neste momento, por favor tente mais tarde')
          return { status: 404 }
        }
        return err.response
      })

    return processRes<ResetPassResponse>(res, 'Algo não confere, por favor tente novamente')
  }

  async resetPass (pass: string, token: string) {
    const url = `${PATH}/api/password_reset/confirm/`

    const body = {
      password: pass,
      token
    }

    const res = await axios.post(url, body)
      .catch(err => {
        if (err.message && (err.message === 'Network Error' || err.message === 'Request failed with status code 500')) {
          console.log('Não foi possível acessar o servidor neste momento, por favor tente mais tarde')
          return { status: 404 }
        }
        return err.response
      })

    return processRes<ResetPassResponse>(res, 'Algo não confere, por favor tente novamente')
  }
}

const api = new Api()

export default class ApiNobile {
  static AUTH = {
    login: api.login,
    logout: api.logout,
    update: api.updatePass,
    reset: api.resetPass,
    requestReset: api.recoverPass
  };

  static GET = {
    years: api.getYears,
    periods: api.getPeriodsByYear,
    week: api.getWeek
  }
}
