































import { Component, Vue } from 'vue-property-decorator'
import Menu from './Menu.vue'

import AuthService from '@/core/service/auth'
import DEVICE from '@/core/service/device'

@Component({
  components: {
    Menu
  }
})
export default class Dash extends Vue {
  visible = false;

  created (): void {
    if (!AuthService.isAuthenticated) {
      this.$router.replace('/login')
    }
    this.visible = !this.isMobile
  }

  get isMobile (): boolean {
    return DEVICE.isMobile.state
  }

  toggleMobile (): void {
    if (!this.isMobile) return
    this.toggle()
  }

  toggle (): void {
    this.visible = !this.visible
  }
}
